import React from "react";

import Skeleton from "components/Skeleton";

const SelectSkeleton = () => {
  return (
    <div className="space-y-2 py-2.5 px-4">
      {[...Array(7)]?.map((item, index) => (
        <div key={index}>
          <Skeleton className="h-4" />
          <Skeleton className="h-2.5 w-4/5" />
        </div>
      ))}
    </div>
  );
};

export default SelectSkeleton;
