"use client";

import { useState, useEffect, useMemo, useCallback } from "react";
import { ChevronsUpDown, Check, LucideIcon } from "lucide-react";
import clsx from "clsx";
import sortByFn from "lodash/sortBy";
import { cn } from "lib/utils";
import { Button } from "components/ui/button";
import { RecordItem } from "types/common";
import Icon from "components/Icon";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem } from "./Command";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";

export type ComboBoxOption = {
  id?: string;
  value?: string;
  title: string;
  icon?: LucideIcon | string;
  onClick?: (value?: ComboBoxValue | null, selectedOption?: ComboBoxOption) => void;
} & RecordItem;

export type ComboBoxValue = string;
export function ComboBox({
  options,
  onSelect,
  defaultValue,
  defaultLabel,
  useAsDropdown = false,
  triggerBtnClassName,
  disabled = false,
  contentPopoverClassName,
  showClear = false,
  sortBy = "az"
}: {
  options: ComboBoxOption[];
  onSelect?: (value?: ComboBoxValue | null, selectedOption?: ComboBoxOption) => void;
  defaultValue?: string | number | null;
  defaultLabel: string;
  useAsDropdown?: boolean; // if true then it works as dropdown and not set the selected value
  triggerBtnClassName?: string;
  disabled?: boolean;
  contentPopoverClassName?: string;
  showClear?: boolean;
  sortBy?: "az" | "za" | "none";
}) {
  const [open, setOpen] = useState(false);
  const [selectedValue, setSelectedValue] = useState<string | null>(null);

  const finalOptions = useMemo(() => {
    const sortOptions =
      sortBy === "az"
        ? sortByFn(options, (item) => item.title?.toLowerCase())
        : sortBy === "za"
        ? sortByFn(options, (item) => item.title?.toLowerCase()).reverse()
        : options;
    return sortOptions;
  }, [options, sortBy]);

  const showClearBtn = useMemo(() => showClear && !!selectedValue, [showClear, selectedValue]);
  const handleClear = useCallback(() => {
    onSelect?.(null);
    setOpen(false);
  }, [onSelect]);

  useEffect(() => {
    if (useAsDropdown) return;
    const defaultOption = finalOptions.find((option) => option.value === defaultValue);
    if (defaultOption && defaultOption.title !== selectedValue) {
      setSelectedValue(defaultOption.title);
    } else if (!defaultOption && !!selectedValue) {
      setSelectedValue(null);
    }
  }, [defaultValue, finalOptions, useAsDropdown, selectedValue]);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={clsx(
            disabled
              ? "bg-base-disabled flex dark:bg-base-dark-disabled cursor-not-allowed pointer-events-none"
              : "hover:bg-neutral-200 active:bg-neutral-300 hover:shadow-none active:shadow-none dark:hover:bg-neutral-dark-200 bg-transparent dark:active:bg-neutral-dark-300",
            "whitespace-nowrap text-sm w-full max-w-full font-medium border border-neutral-300  shadow-50  dark:border-neutral-dark-300 !rounded-md",
            triggerBtnClassName
          )}
          disabled={disabled}
        >
          <span className={clsx("flex-1 overflow-hidden text-ellipsis text-start", !selectedValue && "font-normal")}>
            {selectedValue || defaultLabel}
          </span>
          <ChevronsUpDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent
        className={clsx(
          "max-h-[300px] w-full p-0 border-neutral-300 dark:border-neutral-dark-300",
          contentPopoverClassName
        )}
      >
        <Command>
          <CommandInput
            placeholder="Search"
            className="!test-xs placeholder:text-xs"
            showClearBtn={showClearBtn}
            onClear={handleClear}
          />
          <CommandEmpty>No Results.</CommandEmpty>
          <CommandGroup className="customScrollBar max-h-[250px]  !mt-0 overflow-y-auto">
            {finalOptions.map((option, index) => (
              <CommandItem
                key={option.id || option.value || index}
                onSelect={() => {
                  if (option.onClick) {
                    option.onClick(option.value, option);
                  } else {
                    onSelect?.(option.value, option);
                  }
                  if (!useAsDropdown) {
                    setSelectedValue(option.title);
                  }
                  setOpen(false);
                }}
              >
                {!useAsDropdown && (
                  <Check
                    className={cn("mr-2 !h-4 !w-4", selectedValue === option.title ? "opacity-100" : "opacity-0")}
                  />
                )}
                {option.title}
                {option.icon && (
                  <div className="ml-auto">
                    {typeof option.icon === "string" ? (
                      <Icon name={option.icon as any} className="h-4 w-4" />
                    ) : (
                      <option.icon className="h-4 w-4" />
                    )}
                  </div>
                )}
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
