"use client";

import { forwardRef } from "react";
import dynamic from "next/dynamic";
import { X } from "lucide-react";

import { cn } from "lib/utils";

const Dialog = dynamic(() => import("@radix-ui/react-dialog").then((mod) => mod.Dialog), { ssr: false });

const DialogTrigger = dynamic(() => import("@radix-ui/react-dialog").then((mod) => mod.Trigger), { ssr: false });
const DialogPortalComp = dynamic(() => import("@radix-ui/react-dialog").then((mod) => mod.Portal), { ssr: false });
const DialogOverlayComp = dynamic(() => import("@radix-ui/react-dialog").then((mod) => mod.Overlay), { ssr: false });
const DialogContentComp = dynamic(() => import("@radix-ui/react-dialog").then((mod) => mod.Content), { ssr: false });
const DialogCloseComp = dynamic(() => import("@radix-ui/react-dialog").then((mod) => mod.Close), { ssr: false });
const DialogTitleComp = dynamic(() => import("@radix-ui/react-dialog").then((mod) => mod.Title), { ssr: false });
const DialogDescriptionComp = dynamic(() => import("@radix-ui/react-dialog").then((mod) => mod.Description), {
  ssr: false
});

const DialogPortal = ({ className, ...props }: any) => <DialogPortalComp className={cn(className)} {...props} />;

const DialogOverlay = forwardRef<
  React.ElementRef<typeof DialogOverlayComp>,
  React.ComponentPropsWithoutRef<typeof DialogOverlayComp>
>(({ className, ...props }, ref) => (
  <DialogOverlayComp
    // @ts-ignore
    ref={ref}
    className={cn(
      "fixed inset-0 z-50  bg-neutral-0/80   backdrop-blur-sm data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0",
      className
    )}
    {...props}
  />
));
DialogOverlay.displayName = DialogOverlayComp.displayName;

const DialogContent = forwardRef<
  React.ElementRef<typeof DialogContentComp>,
  React.ComponentPropsWithoutRef<typeof DialogContentComp>
>(({ className, children, ...props }, ref) => (
  <DialogPortal>
    <DialogOverlay />
    <DialogContentComp
      // @ts-ignore
      ref={ref}
      className={cn(
        "fixed left-[50%] top-[50%] z-50 grid w-full max-w-lg translate-x-[-50%] translate-y-[-50%] overflow-y-auto bg-[#F1EFE2] duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%] sm:rounded-2xl md:w-full",
        className
      )}
      {...props}
    >
      {children}
      <DialogCloseComp className="absolute right-6 top-4 rounded-sm opacity-70 ring-offset-white  transition-opacity hover:opacity-100 focus:outline-none focus:ring-2 focus:ring-base-disabled focus:ring-offset-2 disabled:pointer-events-none data-[state=open]:bg-[#F1EFE2] data-[state=open]:text-primary-700  ">
        <X size={24} color="#000" className="h-8 w-8" />
        <span className="sr-only">Close</span>
      </DialogCloseComp>
    </DialogContentComp>
  </DialogPortal>
));
DialogContent.displayName = DialogContentComp.displayName;

const DialogHeader = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex", className)} {...props} />
);
DialogHeader.displayName = "DialogHeader";

const DialogFooter = ({ className, ...props }: React.HTMLAttributes<HTMLDivElement>) => (
  <div className={cn("flex flex-col-reverse sm:flex-row sm:justify-end sm:space-x-2", className)} {...props} />
);
DialogFooter.displayName = "DialogFooter";

const DialogTitle = forwardRef<
  React.ElementRef<typeof DialogTitleComp>,
  React.ComponentPropsWithoutRef<typeof DialogTitleComp>
>(({ className, ...props }, ref) => (
  <DialogTitleComp
    // @ts-ignore
    ref={ref}
    className={cn("text-lg font-semibold leading-none tracking-tight", className)}
    {...props}
  />
));
DialogTitle.displayName = DialogTitleComp.displayName;

const DialogDescription = forwardRef<
  React.ElementRef<typeof DialogDescriptionComp>,
  React.ComponentPropsWithoutRef<typeof DialogDescriptionComp>
>(({ className, ...props }, ref) => (
  <DialogDescriptionComp
    // @ts-ignore
    ref={ref}
    className={cn("text-sm text-primary-700", className)}
    {...props}
  />
));
DialogDescription.displayName = DialogDescriptionComp.displayName;

export {
  Dialog,
  DialogTrigger,
  DialogContent,
  DialogHeader,
  DialogFooter,
  DialogTitle,
  DialogDescription,
  DialogCloseComp as DialogClose
};
