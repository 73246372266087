import React, { Fragment, useState, useRef, useMemo, useEffect } from "react";
import clsx from "clsx";
import { Combobox, Transition } from "@headlessui/react";
import sortByFn from "lodash/sortBy";
import noop from "lodash/noop";
import { X as RemoveIcon, CheckCircle2 as CheckIcon, ChevronDownIcon } from "lucide-react";
import Popper from "@mui/material/Popper";

import { AvatarSize } from "utils/constants";
import type { SelectOption, SelectOptions, SelectValue } from "types/common";
import Avatar from "components/Avatar";
import IconButton from "components/IconButton";
import SelectSkeleton from "./SelectSkeleton";

type SelectProps = {
  label: string;
  options?: SelectOptions;
  value?: SelectValue;
  onChange: (value: SelectValue) => void;
  onChangeTrigger?: (value: SelectValue) => void;
  disabled?: boolean;
  required?: boolean;
  showResults?: boolean;
  renderOptions?: (wrapper: any) => React.ReactNode;
  renderOption?: (option: SelectOption) => React.ReactNode;
  renderResult?: (
    option: SelectOption,
    onRemove: (valueToRemove: string | number, titleToRemove: string) => void
  ) => React.ReactNode;
  className?: string;
  error?: {
    message: string;
  };
  showButton?: boolean;
  multiple?: boolean;
  mRef?: React.RefObject<HTMLDivElement>;
  inputRef?: React.RefObject<HTMLInputElement>;
  allowCreate?: boolean;
  handleCreate?: (value: SelectOption) => void;
  sortBy?: "az" | "za" | "none";
  isLoading?: boolean;
  classNameInput?: string;
  classNameArrow?: string;
  placeholder?: string;
  onClear?: (value: boolean) => void;
  showClear?: boolean;
  hidePortal?: boolean;
  onRemoveClicked?: (value: SelectOption | SelectOption[]) => void;
  showLabelInOptionsDropdown?: boolean;
};

const Select = ({
  label = "",
  options = [],
  value: valueProp,
  disabled = false,
  onChange = noop,
  onChangeTrigger = noop,
  className = "",
  renderOption,
  renderOptions,
  renderResult,
  showResults = true,
  error,
  showButton = true,
  multiple = false,
  mRef,
  inputRef,
  allowCreate = false,
  handleCreate = noop,
  sortBy = "az",
  isLoading = false,
  classNameInput = "",
  classNameArrow = "",
  placeholder = " ",
  showClear = false,
  onClear = noop,
  hidePortal = false,
  onRemoveClicked,
  showLabelInOptionsDropdown = false,
  ...rest
}: SelectProps) => {
  const isError = !!error;

  const containerRef = useRef<HTMLDivElement | null>(null);
  const optionsRef = useRef<HTMLDivElement | null>(null);

  const [query, setQuery] = useState("");
  const [openOptions, setOpenOptions] = useState(false);
  const [targetElement, setTargetElement] = useState<HTMLDivElement | null>(null);

  const value = useMemo(() => {
    if (!valueProp && multiple) return [];
    if (!valueProp) return null;

    if (multiple) {
      const multipleValue = valueProp as SelectOption[];
      const createdValue = Array.from(new Set(multipleValue.filter((x) => x.value === null)?.map((x) => x.title)));

      const result = multipleValue.map((item) => {
        return options?.find((option) => option.value === item.value);
      });

      if (allowCreate && createdValue) {
        return [...result, ...createdValue.map((x) => ({ title: x, value: null }))];
      }

      return result;
    }

    const singleValue = valueProp as SelectOption;
    const singleResult = options?.find((option) => option.value === singleValue.value);

    if (allowCreate && valueProp && !singleResult) {
      return valueProp;
    }
    return singleResult;
  }, [valueProp, options, multiple, allowCreate]);

  const filtered = useMemo(() => {
    const sortOptions =
      sortBy === "az"
        ? sortByFn(options, (item) => item.title?.toLowerCase())
        : sortBy === "za"
          ? sortByFn(options, (item) => item.title?.toLowerCase()).reverse()
          : options;

    return query === ""
      ? sortOptions
      : sortOptions?.filter((option) =>
          option.title?.toLowerCase().replace(/\s+/g, "").includes(query.toLowerCase().replace(/\s+/g, ""))
        );
  }, [options, query, sortBy]);

  const handleSelectOption = (newValue: SelectOption | SelectOption[]) => {
    onClear(false);
    onChange?.(newValue);
    onChangeTrigger?.(newValue);

    if (!multiple) {
      setOpenOptions(false);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event: TouchEvent | MouseEvent) => {
      const isInContainer = containerRef.current?.contains(event.target as Node);
      const isInOptions = optionsRef.current?.contains(event.target as Node);

      if (!isInContainer && openOptions && !isInOptions) {
        setTimeout(() => {
          setOpenOptions(false);
        }, 100);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [containerRef, openOptions]);

  const handleRemoveSelectedOption = (valueToRemove: string | number, titleToRemove: string) => {
    let valueRemoved = null;
    const newSelected = (value as SelectOption[]).filter((item) => {
      if (valueToRemove !== null) {
        if (item.value === valueToRemove) {
          valueRemoved = item;
        }
      } else {
        if (item.title === titleToRemove) {
          valueRemoved = item;
        }
      }
      return valueToRemove !== null ? item.value !== valueToRemove : item.title !== titleToRemove;
    });

    if (typeof onRemoveClicked === "function" && valueRemoved) {
      onRemoveClicked(valueRemoved as SelectOption);
      return;
    }
    onChange?.(newSelected);
  };

  const renderResultDefault = (itemSelected: SelectOption) => {
    if (!multiple) return null;

    return (
      <div
        className="flex items-center space-x-1 rounded-full bg-neutral-100 py-2 pl-2 pr-2 text-xs dark:bg-neutral-dark-100"
        key={itemSelected?.value || itemSelected?.title}
      >
        {itemSelected?.avatar ? <Avatar size={AvatarSize.XS} src={itemSelected?.avatar} className="mr-1.5" /> : null}
        <span className="ml-1">{itemSelected?.title}</span>
        <RemoveIcon
          className="cursor-pointer p-1.5"
          onClick={() => handleRemoveSelectedOption(itemSelected?.value, itemSelected?.title)}
        />
      </div>
    );
  };

  const renderItems = () => {
    return (
      <div className="my-1" style={{ minWidth: targetElement?.scrollWidth, zIndex: 20 }} ref={optionsRef}>
        <Transition
          as={Fragment}
          leave="transition ease-in duration-100"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
          show={openOptions}
        >
          <Combobox.Options
            className={clsx(
              "z-50 max-h-[237px] w-full min-w-[250px] overflow-y-auto bg-neutral-0 px-2 py-1 pb-4 text-sm text-base-secondary shadow-lg dark:bg-neutral-dark-0",
              label && showLabelInOptionsDropdown ? "pt-0" : "pt-2"
            )}
            static
          >
            <>
              {label && showLabelInOptionsDropdown && (
                <li
                  className={
                    "pointer-events-none sticky top-0 z-10 border-b bg-neutral-0 py-2 pl-0 pr-4 pt-4 text-xs font-semibold text-base-primary dark:bg-neutral-dark-0 dark:text-base-dark-primary"
                  }
                >
                  {label}
                </li>
              )}
              {isLoading && <SelectSkeleton />}

              {filtered?.length === 0 && !allowCreate && query !== "" && (
                <div
                  key="nothing-found-option"
                  className="relative cursor-default select-none px-4 py-2 text-base-disabled dark:text-base-dark-disabled"
                >
                  Nothing found.
                </div>
              )}

              {renderOptions ? (
                renderOptions(Combobox.Option)
              ) : (
                <>
                  {filtered?.map((option, idx) => (
                    <Combobox.Option
                      key={`${option.value} + ${idx}`}
                      className={({ active }) =>
                        clsx(
                          "relative cursor-pointer select-none px-4 py-2.5 dark:text-neutral-300",
                          active && "bg-neutral-100 dark:bg-neutral-dark-100"
                        )
                      }
                      value={option}
                    >
                      {({ selected }) => (
                        <>
                          {renderOption ? (
                            renderOption(option)
                          ) : (
                            <span className="block truncate">{option?.title}</span>
                          )}
                          {selected ? (
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2  ">
                              <CheckIcon className="h-5 w-5" aria-hidden="true" />
                            </span>
                          ) : null}
                        </>
                      )}
                    </Combobox.Option>
                  ))}
                  {allowCreate &&
                    !!(query || "").length &&
                    !filtered.find((x) => x.title === query) &&
                    (multiple
                      ? !(value as SelectOption[])?.find((x) => x.value === null && x.title === query)
                      : (value as SelectOption)?.title !== query) && (
                      <Combobox.Option
                        onClick={() => handleCreate({ title: query, value: null })}
                        value={{ title: query, value: null }}
                      >
                        <div className={clsx("relative cursor-pointer select-none px-4 py-2.5 hover:bg-neutral-100")}>
                          {`Create "${query}"`}
                        </div>
                      </Combobox.Option>
                    )}
                </>
              )}
            </>
          </Combobox.Options>
        </Transition>
      </div>
    );
  };

  const renderCombobox = () => {
    const id = openOptions ? "popper" : undefined;

    return (
      <div className="relative">
        <div className="relative w-full cursor-default rounded-lg bg-neutral-0 text-left">
          <div className="relative w-full" ref={setTargetElement}>
            <Combobox.Input
              className={clsx(
                "blank:py-4 peer h-14 w-full border-none pb-2 pl-4 pr-6 pt-6 dark:bg-neutral-dark-0",
                "rounded-lg text-sm  outline-none ring-1 ring-neutral-300  dark:ring-neutral-dark-300",
                "focus:ring-2 focus:ring-base-secondary",
                "disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-base-disabled disabled:ring-neutral-200",
                { "ring-2 ring-red-700 focus:ring-red-700": isError },
                classNameInput
              )}
              onChange={(event) => setQuery(event.target.value)}
              placeholder={label ? " " : placeholder}
              ref={inputRef}
              displayValue={(option: SelectOption | SelectOption[]) =>
                multiple ? query : (option as SelectOption)?.title
              }
              onClick={() => setOpenOptions(!openOptions)}
              onKeyPress={() => !openOptions && setOpenOptions(true)}
              autoComplete="off"
              value={query}
            />
            {label && (
              <label
                className={clsx(
                  "absolute left-4 top-2 line-clamp-1",
                  "pointer-events-none cursor-text text-xs transition-all",
                  "peer-placeholder-shown:top-5 peer-placeholder-shown:text-xs",
                  "transition-all duration-300 peer-focus:top-2 peer-focus:text-xs",
                  isError ? "text-red-700" : "text-base-primary dark:text-base-dark-primary"
                )}
              >
                {label}
              </label>
            )}

            {!multiple && !disabled && value && showClear && (
              <div className="absolute right-10 top-3.5">
                <IconButton
                  icon={RemoveIcon}
                  size="xs"
                  color="transparent"
                  onClick={() => {
                    setQuery("");
                    onClear(true);
                    onChange?.(null);
                  }}
                  className="hover:!bg-neutral-100 active:!bg-neutral-200"
                  classNameIcon="!text-base-primary"
                  type="button"
                />
              </div>
            )}

            {showButton && (
              <ChevronDownIcon
                className={clsx("absolute right-[15px] top-[15px] h-5 w-5 text-gray-400", classNameArrow)}
                aria-hidden="true"
                onClick={() => {
                  if (disabled) return;
                  setOpenOptions(!openOptions);
                }}
              />
            )}
          </div>
        </div>
        {hidePortal ? (
          <>{renderItems()}</>
        ) : (
          <Popper
            open={openOptions}
            id={id}
            anchorEl={targetElement}
            disablePortal={false}
            className="z-50"
            placement="bottom-start"
          >
            {renderItems()}
          </Popper>
        )}

        {isError && <div className={clsx("mt-2 text-sm font-medium text-red-700")}>{error?.message}</div>}

        {showResults && (value as SelectOption[])?.length > 0 && (
          <div className="mt-3 flex flex-wrap gap-1">
            {value?.map((itemSelected: SelectOption) =>
              renderResult ? renderResult(itemSelected, handleRemoveSelectedOption) : renderResultDefault(itemSelected)
            )}
          </div>
        )}
      </div>
    );
  };

  return (
    <div ref={mRef} data-testid="Select" className={clsx("w-full", className)} {...rest}>
      <div ref={containerRef} className="w-full">
        {multiple ? (
          <Combobox value={value as any} onChange={handleSelectOption} disabled={disabled} multiple>
            {renderCombobox()}
          </Combobox>
        ) : (
          <Combobox value={value as SelectOption[]} onChange={handleSelectOption} disabled={disabled}>
            {renderCombobox()}
          </Combobox>
        )}
      </div>
    </div>
  );
};

Select.displayName = "Select";

export default Select;
