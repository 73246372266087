export const ToggleSize = {
  SM: "sm",
  MD: "md",
};

export const sizeClassName = {
  [ToggleSize.SM]: "h-5 w-9",
  [ToggleSize.MD]: "h-7 w-12",
};
export const sizeCircleClassName = {
  [ToggleSize.SM]: "h-4 w-4",
  [ToggleSize.MD]: "h-6 w-6",
};
export const sizeIconClassName = {
  [ToggleSize.SM]: "h-4 w-4",
  [ToggleSize.MD]: "h-6 w-6",
};
export const sizeIconPositionClassName = {
  [ToggleSize.SM]: "translate-x-4",
  [ToggleSize.MD]: "translate-x-5",
};
