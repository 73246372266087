import { useQuery } from "@tanstack/react-query";
import useSupabaseBrowser from "utils/supabaseBrowserClient";

import { getPagesForAdmin } from "lib/adminApi";
import { QueryHookOptions } from "types/common";
import useUserType from "./useUserType";
import useCurrentUser from "./useCurrentUser";

// Returns pages with base information only
const useAllPagesForAdmin = (options?: QueryHookOptions) => {
  const supabaseClient = useSupabaseBrowser();
  const { userType } = useUserType();
  const currentUser = useCurrentUser();

  const { data, isLoading, refetch } = useQuery({
    queryKey: ["pages-all-admin", currentUser?.org_id],
    queryFn: () => getPagesForAdmin(supabaseClient, userType, currentUser?.org_id),
    refetchInterval: false,
    refetchOnWindowFocus: false,
    staleTime: Infinity,
    refetchOnMount: false,
    enabled: !!currentUser?.is_admin,
    ...options
  });

  const getPageTitleBySlug = (slug: string) => {
    return data?.data?.find((page) => page.path === `/${slug}`)?.title;
  };

  return {
    data: data?.data,
    isLoading,
    refetch,
    getPageTitleBySlug
  };
};

export default useAllPagesForAdmin;
