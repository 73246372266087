"use client";

import React, { useState } from "react";
import clsx from "clsx";

type TextFieldProps = {
  component?: string;
  disabled?: boolean;
  label?: string;
  helperText?: string;
  leadingAddOn?: string;
  trailingAddOn?: string;
  trailingIcon?: React.ReactNode;
  className?: string;
  inputClassName?: string;
  error?: { message: string };
  required?: boolean;
  handleError?: boolean;
  hasErrors?: boolean;
  mRef?: React.RefObject<HTMLInputElement>;
  inputRef?: React.RefObject<HTMLInputElement>;
  register?: any;
  name?: string;
} & React.ComponentPropsWithoutRef<"input">;

const TextField = React.forwardRef(
  (
    {
      component = "input",
      disabled = false,
      label = "",
      helperText = "",
      leadingAddOn = "",
      trailingAddOn = "",
      trailingIcon,
      value,
      onBlur,
      onChange,
      className = "",
      inputClassName = "",
      mRef,
      inputRef,
      hasErrors = false,
      error,
      required = false, // TODO: Fix error handling
      handleError = false,
      register,
      name,
      ...rest
    }: TextFieldProps,
    ref: React.Ref<HTMLInputElement>
  ) => {
    const isError = hasErrors || !!error;
    const [isEmptyError, setIsEmptyError] = useState(false); // NOTE: This is a temporary fix will be refactored

    const handleOnBlurError = (e: React.FocusEvent<HTMLInputElement, Element>) => {
      if (required && !e.target.value) {
        setIsEmptyError(true);
      }
      if (onBlur) {
        onBlur(e);
      }
    };

    return (
      <div data-testid="TextField" className={clsx("relative dark:bg-neutral-dark-0", className)} ref={mRef}>
        {React.createElement(component, {
          ref: inputRef || ref,
          type: "text",
          placeholder: " ",
          onChange,
          onBlur: handleOnBlurError,
          onFocus: () => setIsEmptyError(false),
          className: clsx(
            "peer blank:py-4 h-14 w-full border-none pt-6 pb-2 dark:bg-neutral-dark-0",
            "rounded-lg text-sm text-base-primary outline-none ring-1 ring-neutral-300 dark:text-neutral-300 dark:ring-neutral-dark-300",
            "focus:ring-2 focus:ring-base-secondary",
            "disabled:cursor-not-allowed disabled:bg-neutral-200 disabled:text-base-disabled disabled:ring-neutral-200",
            leadingAddOn ? "pl-8" : "pl-4",
            trailingAddOn ? "pr-14" : "pr-4",
            inputClassName,
            { "ring-2 ring-red-700 focus:ring-red-700": isError }
          ),
          disabled,
          value,
          ...(register ? register(name) : {}),
          ...rest,
        })}
        {leadingAddOn && (
          <p
            className={clsx(
              "mr-3 opacity-100 peer-placeholder-shown:opacity-0 peer-focus:opacity-100",
              "absolute left-4 top-[1.58rem]",
              "pointer-events-none text-base text-base-disabled transition-all duration-300"
            )}
          >
            {leadingAddOn}
          </p>
        )}
        {trailingAddOn && (
          <p
            className={clsx(
              "mr-3 opacity-100 peer-placeholder-shown:opacity-0 peer-focus:opacity-100",
              "absolute right-0 top-[1.58rem]",
              "pointer-events-none text-base text-base-disabled transition-all duration-300"
            )}
          >
            {trailingAddOn}
          </p>
        )}
        <label
          className={clsx(
            "absolute left-4 top-2",
            "pointer-events-none cursor-text text-xs transition-all",
            "peer-placeholder-shown:top-5 peer-placeholder-shown:text-xs",
            "transition-all duration-300 peer-focus:top-2 peer-focus:text-xs",
            isError || isEmptyError ? "text-red-700" : ""
          )}
        >
          {label}
        </label>
        {!disabled && isError && (
          <span className={clsx("mb-1.5 mt-2 block text-base font-medium text-red-700")}>{error?.message}</span>
        )}
        {!disabled && handleError && isEmptyError && (
          <span className={clsx("mb-1.5 mt-2 block text-base font-medium text-red-700")}>This is a required field</span>
        )}
        {helperText && (
          <span className={clsx("dark:text-base-darkdisabled mt-2 block text-xs text-base-disabled")}>
            {helperText}
          </span>
        )}
        {trailingIcon && (
          <div
            className={clsx(
              "mr-3 mt-2",
              "absolute right-0 top-[50%] translate-y-[-50%]",
              "pointer-events-none text-base text-base-disabled transition-all duration-300"
            )}
          >
            {trailingIcon}
          </div>
        )}
      </div>
    );
  }
);

TextField.displayName = "TextField";

export default TextField;
