import React, { Fragment } from "react";
import clsx from "clsx";
import { Switch } from "@headlessui/react";
import { CheckCircle2 as DefaultCheckIcon, LucideIcon } from "lucide-react";

import { ToggleSize, sizeClassName, sizeCircleClassName, sizeIconPositionClassName, sizeIconClassName } from "./utils";

type ToggleProps = {
  size?: string;
  label?: string;
  descriptor?: string;
  isLabelSmall?: boolean;
  disabled?: boolean;
  position?: "left" | "right";
  className?: string;
  checked?: boolean;
  onChange: (checked: boolean) => void;
  mRef?: React.RefObject<HTMLDivElement> | null;
  checkedIcon?: LucideIcon | React.FunctionComponent<{ className: string }>;
  checkedIconColor?: string;
  checkedSwitchBgColor?: string;
  defaultChecked?: boolean;
  labelClassName?: string;
};

const Toggle = ({
  label = "",
  descriptor = "",
  isLabelSmall = false,
  disabled = false,
  className = "",
  checked,
  defaultChecked,
  onChange,
  position = "right",
  size = ToggleSize.MD,
  mRef,
  checkedIcon: CheckedIcon = DefaultCheckIcon,
  checkedIconColor = "text-primary-700 dark:text-base-secondary",
  checkedSwitchBgColor = "bg-primary-700 dark:bg-primary-dark-700",
  labelClassName,
  ...rest
}: ToggleProps) => {
  return (
    <div
      data-testid="Toggle"
      ref={mRef}
      className={clsx(
        "relative w-fit",
        "cursor-pointer",
        position === "left" ? "flex flex-row-reverse justify-end" : "justify-between",
        className
      )}
    >
      {/* Texts */}
      {label && (
        <div
          className={clsx("mr-6 flex-col align-baseline", {
            "ml-4": position === "left"
          })}
        >
          <div
            className={clsx(
              disabled
                ? "text-base-disabled dark:text-base-dark-disabled"
                : "text-base-primary dark:text-base-dark-primary",
              descriptor ? "font-medium" : "font-normal",
              isLabelSmall ? "text-sm" : "text-base",
              labelClassName
            )}
          >
            {label}
          </div>
          <div
            className={clsx(
              "dark:text-neutral-300",
              disabled ? "text-base-disabled" : "text-base-primary dark:text-base-dark-primary",
              "text-sm",
              "mt-0.5",
              "whitespace-pre-wrap"
            )}
          >
            {label ? descriptor : ""}
          </div>
        </div>
      )}
      <Switch
        onChange={onChange}
        disabled={disabled}
        checked={checked}
        defaultChecked={defaultChecked}
        className={clsx(
          disabled ? "bg-neutral-300" : "",
          checked ? checkedSwitchBgColor : "dark:bg-background bg-neutral-200",
          "rounded-full border-none p-0.5",
          "inline-flex flex-shrink-0",
          "transition-colors duration-200 ease-in-out",
          "focus:outline-none focus-visible:ring-2",
          sizeClassName[size]
        )}
        {...rest}
      >
        {({ checked: switchChecked }) => (
          <span
            aria-hidden="true"
            className={clsx(
              disabled ? "bg-neutral-200" : "bg-neutral-0",
              "rounded-full",
              "pointer-events-none relative",
              "transform ring-0",
              switchChecked ? sizeIconPositionClassName[size] : "translate-x-0",
              "transition duration-200 ease-in-out",
              sizeCircleClassName[size]
            )}
          >
            <CheckedIcon
              className={clsx(
                "absolute left-0 top-0",
                sizeIconClassName[size],
                switchChecked
                  ? disabled
                    ? "text-base-disabled"
                    : checkedIconColor
                  : disabled
                    ? "text-neutral-200"
                    : "text-neutral-0"
              )}
            />
          </span>
        )}
      </Switch>
    </div>
  );
};

export default Toggle;
